.vl-notification {
    display: inline-block;
    position: relative;

    &__counter {
        position: absolute;
        right: 2rem;
        top: -.5rem;
    }

    .vl-popover {
        &__content-overflow {
            padding: 0;
        }

        &__link-list {
            &__item {
                .vl-functional-header__row {
                    padding: .6rem 2rem !important;
                    margin: 1rem 0 !important;
                }
            }

            &__separator {
                margin: 0 !important;
            }
        }

        &__notification {
            &:hover {
                background-color: #f3f5f6;
                cursor: pointer;
            }

            &-item {
                display: flex;
                > button {
                    min-width: 1.25rem;
                    max-width: 1.25rem;
                    margin: -.6rem 1rem -.6rem 0;
                    background-color: #05c;
                }
            }

            &--unread {
                margin: 0 1.5rem 0 0;
            }

            &--read {
                margin: 0 2.25rem !important;
            }
        }
    }
}