.vl-data-table-custom {
    position: relative;

    &__loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, .7);
        z-index: 3;

        &-message {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__action_hover {
        cursor: pointer;
    }
}

.is-loading {
    min-height: 60rem;
}